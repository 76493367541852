import React from 'react';
import starRatingImage from '../../../pages/images/star-ratings-sprite.png';
import styled from 'styled-components';

const Div = styled.div`
.ds-comment {
  max-width: 800px;
  min-width: 300px;
}

.ds-ratings {
  font-size: 0.9em;
}

.ds-breakdown {
  padding: 0px;
}

.ds-title {
  font-weight: bold;
}

.ds-average {
  font-size: 100%;
  font-weight: bold;
}

.ds-average-max {
  font-weight: normal;
  margin: 0px 0px 0px 3px;
  color: #777;
}

.ds-lcol {
  max-width: 140px;
  min-width: 100px;
  width: 20%;
  float: left;
  text-align: left;
}

.ds-rcol {
  max-width: 660px;
  min-width: 200px;
  width: 70%;
  float: left;
}

.ds-date {
  margin: 0px 5px;
  font-size: 90%;
}

.ds-pubs {
  margin: 0px 5px;
  font-size: 84%;
  display: block;
}

.ds-body {
  margin: 0px 0px 10px 0px !important;
}

.ds-author {
  float: left;
  font-size: 90%;
}

.ds-provider {
  font-size: 80%;
  margin: 0 0 10px;
}

.ds-provider-label {
  color: #777;
  font-weight: normal;
}

.ds-questiontext {
  font-weight: bold;
}

.ds-poweredby {
  text-align: center;
  margin: 20px 0px;
  font-size: 80%;
}

.ds-clear {
  clear: both;
}

hr.ds-divider {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eeeeee;
}

span.ds-stars {
  display: inline-block;
  background: url(${starRatingImage}) 0 0 repeat-x;
  width: 88px;
  height: 16px;
  max-width:88px;
}

span.ds-stars5 {
  background-position: 0 -160px;
}

span.ds-stars45 {
  background-position: 0 -144px;
}

span.ds-stars4 {
  background-position: 0 -128px;
}

span.ds-stars35 {
  background-position: 0 -112px;
}

span.ds-stars3 {
  background-position: 0 -96px;
}

span.ds-stars25 {
  background-position: 0 -80px;
}

span.ds-stars2 {
  background-position: 0 -64px;
}

span.ds-stars15 {
  background-position: 0 -48px;
}

span.ds-stars1 {
  background-position: 0 -32px;
}

span.ds-stars05 {
  background-position: 0 -16px;
}

span.ds-stars0 {
  background-position: 0 0px;
}

.ds-commentprefix {
  margin: 0px 0px 20px 0px;
}

.ds-commentprefix .ds-stars {
  float: left;
  margin: 0px 10px 0px 0px;
}

.ds-commentprefix .ds-ratingcount {
  font-size: 100%;
  margin: 0 10px;
  color: #777;
}

.ds-comments-more {
  text-align: center;
  margin: 20px 0px;
}

.ds-breakdown .ds-stars,
.ds-breakdown .ds-average {
  float: left;
  margin: 0px 10px 0px 0px;
}

.ds-breakdown ul {
  margin: 0px;
  padding: 0px;
}

.ds-breakdown ul li {
  list-style: none;
  margin: 0px 0px 10px 0px;
}

.ds-distribution ul {
  padding: 0px;
  margin: 0px;
}

.ds-distribution li {
  cursor: pointer;
  list-style: none;
  height: 16px;
  margin: 0 0 7px 0;
  clear: both;
}

.ds-distribution .ds-average {
  float: left;
}

.ds-dist-bar {
  width: 120px;
  height: 100%;
  border: solid 1px #aaa;
  margin: 0 10px;
  float: left;
}

.ds-dist-bar-fill {
  background-color: #ffcb35;
  height: 100%;
}

.ds-comment-response {
  padding: 10px 0px;
  font-size: 90%;
}

.ds-comment-response .ds-date {
  margin: 0px;
}

.ds-comment-response .ds-res-body {
  margin: 2px 0px;
}

.ds-comment-response .ds-author {
  float: none;
  font-weight: bold;
}
`

const stars = (props) => {

  let centerRating  = props.center.replace(/(\/)/g, '')

  let name = ``
  let ratingValue = ``
  let bestRating = ``
  let worstRating = ``
  let ratingCount = ``
  
  if(props.allPracticemaxRatings.edges){
    let starsdata = props.allPracticemaxRatings.edges.filter(location => {
      return location.node.shortName.toLowerCase() === centerRating
    });
    if (starsdata[0]){
      name = starsdata[0].node.medicalOrganizationEntity.name
      if(starsdata[0].node.medicalOrganizationEntity.aggregateRating){
        ratingValue = starsdata[0].node.medicalOrganizationEntity.aggregateRating.ratingValue
        bestRating = starsdata[0].node.medicalOrganizationEntity.aggregateRating.bestRating
        worstRating = starsdata[0].node.medicalOrganizationEntity.aggregateRating.worstRating
        ratingCount = starsdata[0].node.medicalOrganizationEntity.aggregateRating.ratingCount
      }
    }
  }

  const starValue = (value) => {
    return (Math.round(value * 2) / 2).toString().replace(".", "")
  } 

  let centerStarsData = `
  <script type="application/ld+json">
  {
    "@context": "http://schema.org",
    "@type": "MedicalOrganization",
    "name": "${name}",
      "aggregateRating": {
        "@type": "AggregateRating",
        "ratingCount": ${ratingCount},
        "bestRating": ${bestRating},
        "worstRating": ${worstRating},
        "ratingValue": ${ratingValue}
    }
  }
  </script>`

  if(!name){centerStarsData = ``}
    
  return (
      <>
        <Div>
          {(ratingValue != '') ?
            <>
              <div itemType="http://schema.org/AggregateRating">
                <span itemProp="name" className="d-none">{name}</span>
                <div itemScope itemProp="aggregateRating" className="nowrap">
                  <span className={`ds-stars ds-stars${starValue(ratingValue)}`} ></span> <span>
                    <a  href={`/${centerRating}/ratings`}>see ratings</a></span>
                    {props.extValues ?
                    <>
                      <div className="ds-xofy"><span className="ds-average" itemProp="ratingValue">{ratingValue}</span><span className="ds-average-max">out of 5 </span> 
                      - <span className="ds-ratings ds-ratingcount" itemProp="ratingCount">{ratingCount}</span> Customer Satisfaction Ratings</div>
                    </>
                    : null}
                </div>
              </div>
            </>
              : <div className="ds-ratings">Rating Not Currently Available</div>}
        </Div>
        <div dangerouslySetInnerHTML={{__html: centerStarsData}} />
      </>
    )
  }

export default stars;