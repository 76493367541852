import {
  MDBCard,
  MDBCardBody,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
} from 'mdbreact'

import FinancialStatementTable from './financialstatementtable'
import React from 'react'
import styled from 'styled-components'

let gmdv = require(`../../ghc_config`)

const MDBCardComp = styled(MDBCard)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 300 !important;
  }
  h5 {
    font-size: 1.15em;
  }
  box-shadow: none;
  div.Ripple-parent {
    text-align: center;
    min-height: 180px;
    line-height: 180px;
    img {
      margin: 0 auto;
      display: inline;
    }
  }
  p {
    font-size: 0.75em;
    line-height: 1.5em;
    margin: 0px auto;
    text-align: justify;
  }
`

const MDBCardBodyComp = styled(MDBCardBody)`
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    a[role="button"]{
        background-color:#ee1C25!important;
        min-width:34%;
        margin-left:10px;
        margin-top:10px;
    }
    div {
        margin-left:10px;
        margin-top:10px;
        margin-bottom:10px;
    }

    @media only screen and (max-width: 1200px) {
    }
    @media only screen and (max-width: 960px) {
        text-align: center;
        a[role="button"]{
            min-width:80%;
            margin-left:auto!important;
            margin-right:auto!important;
        }
        div {
            text-align: left;
        }
        .calContent {
            font-size: 0.9rem;  
        } 
    }
    @media only screen and (max-width: 760px) {
        .calCont {
            h4{
                font-size: 1.2rem;
            }
        }
        .calContent {
            font-size: 1.1rem;
        } 
    }
}
`
const FinancialStatement = (props) => {
  // let inactivedocs = props.financialStatementInactiveUrls.map((i) => {
  //   return {
  //     url: '../CenterFinancialStatement/' + i,
  //     name: i.replace('.pdf', '').replace(/_/g, ' '),
  //   }
  // })
  let activedocs = props.financialStatementUrls.map((i) => {
    return {
      url: '../CenterFinancialStatement/' + i,
      name: i.replace('.pdf', '').replace(/_/g, ' '),
    }
  })
  let docnameandurllist = activedocs //.concat(inactivedocs)
  //console.log(docnameandurllist)
  return (
    <MDBCardComp>
      <MDBCardBodyComp>
        <div>
          {docnameandurllist.map((i) => {
            return (
              <>
                <a href={i.url} target="_blank">
                  <h5>{i.name}</h5>
                </a>
              </>
            )
          })}
        </div>
      </MDBCardBodyComp>
    </MDBCardComp>
  )
}

export default FinancialStatement
