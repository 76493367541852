import React from 'react';

const pageRow = (props) => {
    return (
        <tr className={props.bkColor}>
            <td style={{"textAlign":"center"}}>
                <div className="eventsDate">
                    <div className="topEventsDate">{props.month} {props.year}</div>
                    <div className="btmEventsDate">{props.day}</div>
                </div>
            </td>
            <td  className="calContent"> 
                <a href={props.link}><h5>{props.title}
                <br className="d-none d-lg-block"/> [ See More...]</h5></a>
            </td> 
        </tr>
    )
}

export default pageRow;