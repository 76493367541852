import React from 'react'
import SEO from '../../components/seo'

let gmdv = require(`../../ghc_config`)

const Detail = (props) => {
  let post = props.post
  let titleValue = `${post.title} - ${
    gmdv.selectState(props.post.field_state).spa
  }`
  if (props.cat) {
    titleValue += ` - ${props.cat}`
  }
  let descriptionValue = `${post.title}, `
  descriptionValue += `a Genesis HealthCare facility located at `
  let address = `${post.field_address_fl.trim()}, `
  address += `${post.field_city}, ${gmdv.selectState(post.field_state).spa} ${
    post.field_zip
  }`
  descriptionValue += `${address}, `
  let keywordsArray = descriptionValue
  descriptionValue += `specializing in `
  {
    post.relationships.field_services &&
      post.relationships.field_services.map((careOption) => {
        if (careOption.field_featured) {
          descriptionValue += `${careOption.name}, `
          keywordsArray += `${careOption.name}, `
        }
      })
  }
  descriptionValue += `and .`

  return (
    <SEO
      title={titleValue}
      description={descriptionValue}
      keywords={[keywordsArray]}
      nochat={true}
    />
  )
}

export default Detail
