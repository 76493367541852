const altImage = (nameImage) => {
    nameImage = decodeURI(nameImage)
    nameImage = nameImage.replace(/[0-9]/g, '');
    newName = nameImage.split('_').join(' ')
    newName = jsUcfirst(newName)
    newName = newName.replace(/[^a-zA-Z ]/g, "")
    return newName
};

const jsUcfirst = (string) => {
    string = string.toLowerCase();
    return string.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

module.exports = altImage