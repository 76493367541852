import '../css/carousel.min.css';
import '../css/carousel-custom.css';

import React, { Component } from 'react';

import { Carousel } from 'react-responsive-carousel';

export default class CustomizedCarousel extends Component {
    render() {

        let injectedProps = {};
        injectedProps.ciao = 'test';

        return <Carousel {...this.props}  ></Carousel>
    }
}