import 'react-image-lightbox/style.css'

import React, { useState } from 'react'

import CustomizedCarousel from './customcarousel'
import { GatsbyImage } from 'gatsby-plugin-image'
import Lightbox from 'react-image-lightbox'
import styled from 'styled-components'

const CarouselComp = styled(CustomizedCarousel)`
  .carousel.carousel-slider {
    height: 100%;
  }
  .carousel {
    .slide {
      img {
        height: 100%;
      }
    }
  }
`

const altImage = require('./altImage')

const CarouselCenter = (props) => {
  let imagesC = []
  let images = []
  let imageNames = []
  props.images.field_image_carousel.map((image_carousel) => {
    if (image_carousel.localFile) {
      if (image_carousel.localFile.childImageSharp) {
        imagesC.push(image_carousel.localFile.childImageSharp.carousel)
        images.push(encodeURI(image_carousel.localFile.childImageSharp.lb.src))
        imageNames.push(image_carousel.localFile.name)
      }
    }
  })

  const [lightboxCall, setLightboxCall] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const toggleLightBox = (lightboxID) => {
    setPhotoIndex(lightboxID)
    setLightboxCall(!lightboxCall)
  }

  return (
    <>
      <CarouselComp
        showThumbs={false}
        infiniteLoop={true}
        axis={'horizontal'}
        useKeyboardArrows={true}
        autoPlay={false}
        dynamicHeight={false}
        swipeable={true}
        emulateTouch={true}
        transitionTime={10}
        centerMode={true}
        centerSlidePercentage={50}
      >
        {imagesC.map((imageC, key) => {
          return (
            <div key={key} onClick={() => toggleLightBox(key)}>
              <GatsbyImage
                image={imageC}
                alt={altImage(imageNames[key])}
                loading="eager"
              />
            </div>
          )
        })}
      </CarouselComp>
      {lightboxCall && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setLightboxCall(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          imageCaption={altImage(imageNames[photoIndex])}
          reactModalStyle={{ overlay: { zIndex: '2147483648' } }}
        />
      )}
    </>
  )
}

export default CarouselCenter
