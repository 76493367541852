import Applicationidjson from '../../centers/ratings/applicationidjson';
import React from 'react';
import { useDataClosers } from './ccquery';

let gmdv = require(`../../../ghc_config`);

const listScript = (props) => {

  // Centers
  let locations = useDataClosers().edges
  // Added distances
  let locationsArray = []
  locations.map((item) => {locationsArray.push(item)})
  locations = gmdv.drupalData(props.startPosition, locationsArray)

  // Take three positions
  locations = locations.slice(1, 4);

  return (
    <Applicationidjson 
      allPracticemaxRatings={props.allPracticemaxRatings} 
      locationGroup={locations} />
  );
}

export default listScript