import { MDBCol, MDBIcon, MDBRow } from 'mdbreact';

import InlineBodyTransform from '../../components/inlinebodytransform';
import React from 'react';

const MiddleCenter = (props) => {

    const post = props.post
    const featuredItems = post.relationships.field_services

    return (
        <>
            <MDBRow>
                <MDBCol xs="12" md="12" lg="12" className="col-12 px-3 py-0">
                    <InlineBodyTransform bodyValue={post.body} />
                </MDBCol>
            </MDBRow>
            <MDBRow style={fontSizeP} className="my-0 mx-auto">
                <MDBCol xs="12" md="6" lg="6" className="col-12 white-bk-Genesis mx-auto px-2 py-0 m-1 rounded">
                <span className="centercontact float-left text-nowrap py-0">
                    <MDBIcon icon="fas fa-map-marked-alt" size="1x" className="gray-text-Genesis px-2 mx-0" />
                    <a href={props.dirGoogleLink} target="_blank" className="gray-text-Genesis">{'Maps & Directions'}</a>
                </span>
                <span className="centercontact float-left text-nowrap py-0">
                    <MDBIcon icon="fas fa-info-circle" size="1x" className="gray-text-Genesis px-2 mx-1" />
                    <a className="ap-open gray-text-Genesis">Request Information</a>
                </span>
                <span className="centercontact float-left text-nowrap py-0">
                    <MDBIcon icon="fas fa-mobile-alt" size="1x" className="gray-text-Genesis px-2 mx-1" />
                    <a className="ap-open gray-text-Genesis">Pre-book Your Stay</a>
                </span>
                {(post.field_telephone_number) ?
                <span className="centercontact float-left text-nowrap py-0">
                    <MDBIcon icon="fa fa-phone" size="1x" className="gray-text-Genesis px-2 mx-0" />
                    <a className="gray-text-Genesis" href={`tel:${post.field_telephone_number}`}>Phone: {post.field_telephone_number}</a>
                </span> : null}
                {(post.field_fax_number) ?
                <span className="centercontact float-left text-nowrap py-0">
                        <MDBIcon icon="fa fa-fax" size="1x" className="gray-text-Genesis px-2" />
                        <a className="gray-text-Genesis" href={`tel:${post.field_fax_number}`}>Fax phone: {post.field_fax_number}</a>
                </span> : null}
                </MDBCol>
                <MDBCol xs="12" md="6" lg="6" style={borderColor} className="col-12 mx-auto px-2 py-0  m-1">
                    {featuredItems.map((item, key) => {
                        if (item.field_featured) {
                            let service = item.name;
                            (item.field_service_page_link) ? 
                            service = <a href={item.field_service_page_link} className="red-text-Genesis">{item.name}</a> : null
                            return (
                                <span key={key} className="careptions float-left text-nowrap py-0">
                                    <MDBIcon  icon="fas fa-check" size="1x" className="red-text-Genesis px-2" />
                                    {service}
                                </span>
                            )
                        }
                    })}
                </MDBCol>
            </MDBRow>
        </>
    )
}

export default MiddleCenter

const fontSizeP = {fontSize:"0.9rem"}
const borderColor = {borderColor: "#ee1C25!important"}