import { MDBCard, MDBCardBody, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';

import HappeningsTable from './happeningstable';
import React from 'react';
import styled from 'styled-components';

let gmdv = require(`../../ghc_config`);

const MDBCardComp = styled(MDBCard)`
    h1, h2, h3, h4, h5, h6 {
        font-weight: 300!important;
    }
    h5 {
        font-size: 1.15em;
    }
    box-shadow: none;
    div.Ripple-parent{ 
        text-align: center;
        min-height: 180px;
        line-height: 180px;
        img {
            margin: 0 auto;
            display: inline;
        }
    }
    p {
        font-size: 0.75em;
        line-height: 1.5em;
        margin: 0px auto;
        text-align: justify;
    }
`

const MDBCardBodyComp = styled(MDBCardBody)`
    padding: 0px;
    margin: 0px;
    box-shadow: none;
    a[role="button"]{
        background-color:#ee1C25!important;
        min-width:34%;
        margin-left:0px;
    }
    table {
        margin: 0px;
        padding: 0px;
    }
    th {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        min-width: 60px;
        text-align: center;
    }
    .eventsDate {
        text-align: center;
        border: 0px solid #EE1C25;
        border-radius: 0.4em;
        box-shadow: 3px 2px #d4d5d6;
        background: white;
        width: 45px;
        font-weight: 850;
        color: black;
        font-size: 1.1em;
        margin: 0px auto;  
        padding: 0px;
    }
    .calContent {
        padding: 0.2em;
    }
    @media only screen and (max-width: 1200px) {
        min-height: 370px;
    }
    @media only screen and (max-width: 960px) {
        min-height: 300px; 
        text-align: center;
        a[role="button"]{
            min-width:80%;
            margin-left:auto!important;
            margin-right:auto!important;
        }
        div {
            text-align: left;
        }
        .calContent {
            font-size: 0.9rem;  
        } 
    }
    @media only screen and (max-width: 760px) {
        min-height: 200px;
        .calCont {
            h4{
                font-size: 1.2rem;
            }
        }
        .calContent {
            font-size: 1.1rem;
        } 
    }
}
`

const Events = (props) => {
    return (
        <MDBCardComp>
            <MDBCardBodyComp>
                <MDBTable bordered hover responsive className="rounded-10">
                    <MDBTableHead className="d-none">
                        <tr className="d-none">
                            <th>Dates</th>
                            <th>Events</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {props.EventList.edges.map(({node}, key) => {
                            
                            let date = gmdv.dateProcessed(node.field_happening_s_date);

                            let month = date.toLocaleString('en-us', { month: 'short' });
                            let year = date.getFullYear().toString().substr(2,2);
                            return (
                                <HappeningsTable
                                    key={key}
                                    month={month}
                                    day={date.getDate()}
                                    year={year}
                                    title={node.title}
                                    link={node.path.alias}
                                    bkColor={(key % 2 != 0) ? "graytbl" : "whitetbl"}
                                />
                            )
                        })}
                    </MDBTableBody>
                </MDBTable>
            </MDBCardBodyComp>
        </MDBCardComp>
    )
}


export default Events;