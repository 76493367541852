import InfoList from './ccinfobox'
import { MDBCard } from 'mdbreact';
import React from 'react';
import styled from 'styled-components';
import { useDataClosers } from './ccquery';

const MDBCardComp = styled(MDBCard)`
    width:100%;
    margin: 0px;
    padding:0px;
    position: relative;
    left: 50%;
    top: 14px!important;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
`

let gmdv = require(`../../../ghc_config`);

const Content = (props) => {

  // Centers
  let locations = useDataClosers().edges
  // Added distances
  let locationsArray = []
  locations.map((item) => {locationsArray.push(item)})
  locations = gmdv.drupalData(props.startPosition, locationsArray)

  // Take three positions
  locations = locations.slice(1, 4);

  return (
    <>
    <MDBCardComp>
      <h5 className="text-center my-0 p-2">Nearby Locations</h5>
      {locations.length ? locations.map((item, index) => {
        if (item.node.field_latitude && item.node.field_longitude) {
          return(<InfoList 
            key={index} 
            item={item} 
            index={index} 
            locations={locations}
            allPracticemaxRatings={props.allPracticemaxRatings} />)
          }
        }
      ): null}
    </MDBCardComp>
    </>
  );
}

export default Content