import { graphql, useStaticQuery } from "gatsby";

export const useDataClosers = () => {
  const { allNodeCenter } = useStaticQuery(
    graphql`
      query dataClosers {
        allNodeCenter(filter: {
          # delete 2 when remove kys
          field_center_group: {in: [0, 1, 2]}, 
          status: {eq: true}
        }) {
          edges {
            node {
              title
              field_latitude
              field_longitude
              path {
                alias
              }
              field_address_fl
              field_city
              field_state
              field_zip
              field_center_group
              relationships {
                field_image_carousel {
                  localFile {
                    childImageSharp {
                      list: gatsbyImageData(layout: FIXED width: 82 height: 44)
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allNodeCenter
}