import { MDBCard, MDBCol, MDBRow } from 'mdbreact';

import { Link } from 'gatsby';
import React from "react";
import styled from 'styled-components';

const MDBCardComp = styled(MDBCard)`
  ul {
    padding: "3px",
    backgroundColor: "#fff",
    display: "block",   
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    padding: 5px;
    li {
      display: inline-block!important;
      width:100%;
    }
    @media only screen and (max-width: 767px) {
      -webkit-column-count: 1;
      -moz-column-count: 1;
      column-count: 1;
    }
  }
`

const  centers = (props) => {
  return (
    <MDBRow>
      <MDBCol  xs="12" md="12" lg="12" className="col-12 px-2 py-2 m-0">
        <MDBCardComp className="listCenter">
          <div className="red-bk-Genesis">
            <h5 className="white-text-Genesis shadow-Genesis">
              Services
            </h5>
          </div>
          <ul>  
            {props.services.map((services, sKey) => {
              let resultService = services.name
              //  check if it is a `No Service`
              if (services.id === `d055c173-114a-517c-8a44-f6cd28dd8f44`
              ||  services.name.toLowerCase() === `no service`) {
                resultService = ``
              } 
              else {
                //  check if there is a link to the page
                if (props.linkedItems) {
                  props.linkedItems.map((linkedItem, sPathKey) => {
                    if (linkedItem.node.id === services.id) {
                      resultService = <a key={sPathKey} href={linkedItem.node.field_service_page_link}>{services.name}</a>
                    } 
                  })
                } 
              }
              resultService = <li key={sKey}>{resultService}</li>
              return (resultService)
            })}
          </ul>
        </MDBCardComp>
      </MDBCol>
      <MDBCol  xs="12" md="12" lg="12" className="col-12 px-2 py-2 m-0">
        <MDBCardComp className="listCenter">
          <div className="red-bk-Genesis">
            <h5 className="white-text-Genesis shadow-Genesis">
              Amenities
            </h5>
          </div>
          <ul>  
            {props.amenities.map((amenities, aKey) => {
              let resultAmenity = amenities.name

              //  check if it is a `No Amenity`
              if (amenities.id === `55ded130-d505-5aef-9e5f-e00c7ede7cd7`
              ||  amenities.name.toLowerCase() === `no amenity`) {
                resultAmenity = ``
              } 
              else {
                //  check if there is a link to the page
                if (props.linkedItems) {
                  props.linkedItems.map((linkedItem, aPathKey) => {
                    if (linkedItem.node.id === amenities.id) {
                      resultAmenity = <a key={aPathKey} href={linkedItem.node.field_service_page_link}>{amenities.name}</a>
                    } 
                  })
                } 
              }
              resultAmenity = <li key={aKey}>{resultAmenity}</li>
              return (resultAmenity)
            })}
          </ul>
        </MDBCardComp>
      </MDBCol>
    </MDBRow>
  )
}

export default centers;