import { MDBCard, MDBCardBody, MDBCardImage } from 'mdbreact';

import InlineBodyTransform from '../inlinebodytransform';
import React from 'react';
import styled from 'styled-components';

const MDBCardComp = styled(MDBCard)`
    height: 98%;
    div.Ripple-parent{ 
        text-align: center;
        min-height: 130px;
        line-height: 130px;
        img {
            margin: 0 auto;
            display: inline;
        }
    }
    div.card-body {
        min-height: 150px;
        text-align: center;
        padding-left:4px;
        padding-right:4px;
        padding-top:0px;
        padding-bottom:4px;
        h5 {
            font-size:1.2rem;
            line-height: 1rem;
        }
        span {
            font-size:0.8rem;
            line-height: 0.8rem;
        }
        p {
            padding-top:0.5em;
            text-align:justify;
            line-height: 1rem;
        }
        @media only screen and (max-width: 960px) {
            min-height: 100px;      
        }  
    }
`

const CardExample = (props) => {
    return (
        <MDBCardComp>
            {props.image 
            ? <MDBCardImage className="img-fluid" src={encodeURI(props.image)} alt={props.headerText}  waves /> 
            : <div style={{height: "10px"}}></div>}
            <MDBCardBody>
                <h5>{props.headerText}</h5>
                <span>{props.role}</span>
                <InlineBodyTransform bodyValue={props.Description} />
            </MDBCardBody>
        </MDBCardComp>
    )
}

export default CardExample;