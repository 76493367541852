import { AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { MDBCard, MDBCol, MDBRow } from 'mdbreact';

import React from 'react';
import starRatingImage from '../../../pages/images/star-ratings-sprite.png';
import styled from 'styled-components';

const Div = styled.div`
.ds-comment {
  max-width: 800px;
  min-width: 300px;
}

.ds-breakdown {
  padding: 0px;
}

.ds-title {
  font-weight: bold;
}

.ds-average {
  font-size: 100%;
  font-weight: bold;
  width: fit-content;
  height: 16px;
  float:left;
}

.ds-average-max {
  font-weight: normal;
  margin: 0px 0px 0px 3px;
  color: #777;
}

.ds-lcol {
  max-width: 140px;
  min-width: 100px;
  width: 20%;
  float: left;
  text-align: left;
}

.ds-rcol {
  max-width: 660px;
  min-width: 200px;
  width: 70%;
  float: left;
}

.ds-date {
  clear:both;
  float:left;
  font-size: 90%;
}

.ds-pubs {
  margin: 0px 5px;
  font-size: 84%;
  display: block;
}

.ds-body {
  margin: 0px 0px 10px 0px !important;
}

.ds-author {
  float: left;
  font-size: 90%;
}

.ds-provider {
  font-size: 80%;
  margin: 0 0 10px;
}

.ds-provider-label {
  color: #777;
}

.ds-questiontext {
  font-weight: bold;
  width: fit-content;
  float:left;
}

.ds-poweredby {
  text-align: center;
  margin: 20px 0px;
  font-size: 80%;
}

.ds-clear {
  clear: both;
}

hr.ds-divider {
  margin: 20px 0;
  border: 0;
  border-top: 1px solid #eeeeee;
}

span.ds-stars {
  display: block;
  background: url(${starRatingImage}) 0 0 repeat-x;
  width: 88px;
  height: 16px;
  float:left;
  margin-top:0.4em!Important;
}

span.ds-stars5 {
  background-position: 0 -160px;
}

span.ds-stars45 {
  background-position: 0 -144px;
}

span.ds-stars4 {
  background-position: 0 -128px;
}

span.ds-stars35 {
  background-position: 0 -112px;
}

span.ds-stars3 {
  background-position: 0 -96px;
}

span.ds-stars25 {
  background-position: 0 -80px;
}

span.ds-stars2 {
  background-position: 0 -64px;
}

span.ds-stars15 {
  background-position: 0 -48px;
}

span.ds-stars1 {
  background-position: 0 -32px;
}

span.ds-stars05 {
  background-position: 0 -16px;
}

span.ds-stars0 {
  background-position: 0 0px;
}

.ds-commentprefix {
  margin: 0px 0px 20px 0px;
}

.ds-commentprefix .ds-stars {
  float: left;
  margin: 0px 10px 0px 0px;
}

.ds-commentprefix .ds-ratingcount {
  font-size: 100%;
  margin: 0 10px;
  color: #777;
}

.ds-comments-more {
  text-align: center;
  margin: 20px 0px;
}

.ds-breakdown .ds-stars,
.ds-breakdown .ds-average {
  float: left;
  margin: 0px 10px 0px 0px;
}

.ds-breakdown ul {
  margin: 0px;
  padding: 0px;
}

.ds-breakdown ul li {
  list-style: none;
  margin: 0px 0px 10px 0px;
}

.ds-distribution ul {
  padding: 0px;
  margin: 0px;
}

.ds-distribution li {
  cursor: pointer;
  list-style: none;
  height: 16px;
  margin: 0 0 7px 0;
  clear: both;
}

.ds-distribution .ds-average {
  float: left;
}

.ds-dist-bar {
  width: 120px;
  height: 100%;
  border: solid 1px #aaa;
  margin: 0 10px;
  float: left;
}

.ds-dist-bar-fill {
  background-color: #ffcb35;
  height: 100%;
}

.ds-comment-response {
  padding: 10px 0px;
  font-size: 90%;
}

.ds-comment-response .ds-date {
  margin: 0px;
}

.ds-comment-response .ds-res-body {
  margin: 2px 0px;
}

.ds-comment-response .ds-author {
  float: none;
  font-weight: bold;
}
`

const ratings = (props) => {

    let centerRating  = props.center.replace(/(\/)/g, '')
    let commentsdata = props.allPracticemaxComments

    let ratingValue = 'null'
    
    if(props.allPracticemaxRatings.edges){
      let starsdata = props.allPracticemaxRatings.edges.filter(location => {
        return location.node.shortName.toLowerCase() === centerRating
      });
      if (starsdata[0]){
        if(starsdata[0].node.medicalOrganizationEntity.aggregateRating){
          ratingValue = starsdata[0].node.medicalOrganizationEntity.aggregateRating.ratingValue
        }
      }
    }

    const starValue = (value) => {
        return (Math.round(value * 2) / 2).toString().replace(".", "")
    } 

    return (
      <Div>
        <AccordionItemHeading>
          <AccordionItemButton>
            <span>Ratings and Comments</span>
          </AccordionItemButton>
        </AccordionItemHeading>
        {(ratingValue != 'null') ?
          <AccordionItemPanel>
            <MDBCard style={divstyle} className="px-2 py-2 m-0">
              <div className="ds-distribution" data-ds-clickable="true">
                <span className="ds-average mx-2 my-1">{ratingValue}</span>
                <span className={`ds-stars ds-stars${starValue(ratingValue)} my-1`}></span>
                <span className="ds-questiontext mx-2 my-1">Overall Rating</span>
              </div>
              <hr />
              {(commentsdata.totalCount && commentsdata.totalCount > 0) ?
                commentsdata.edges.map((rating, key) => {
                  let starVal = starValue(rating.node.ratingValue)
                  return(
                    <MDBRow key={key} className="border-bottom m-1">
                      <MDBCol xs="12" md="12" lg="3" className="col-12">
                        <span className={`ds-stars ds-stars${starVal}`}></span>
                        <span className="ds-date">
                          {new Date(rating.node.reviewDate).toLocaleDateString("en-us", { month: "short", day: "2-digit", year: "numeric" })}
                        </span>
                        <span className="ds-date">{rating.node.authorType}</span>
                      </MDBCol>
                      <MDBCol xs="12" md="12" lg="9" className="col-12">
                        <p className="ds-body ds-body-full">
                          {(rating.node.reviews) ?
                          rating.node.reviews.map((item, i) => {
                              return(<span key={i}><i>{item.question}</i><br/>{item.answer}<br/></span>)
                          }) : <div>Comments Not Currently Available</div> }
                        </p>
                      </MDBCol>
                    </MDBRow>
                  )
                }) : <div>Comments Not Currently Available</div>
              }
            </MDBCard>
          </AccordionItemPanel>
        : 
          <AccordionItemPanel>
              <MDBCard style={divstyle} className="ds-comment px-2 py-2 m-0">
                Rating Not Currently Available
              </MDBCard>
          </AccordionItemPanel>
      }
      </Div>
    )
}

export default ratings;

const divstyle = {
  backgroundColor: "#fff", 
}