import { GatsbyImage } from "gatsby-plugin-image";
import React from 'react';
import Stars from '../ratings/closerstars';
import styled from 'styled-components';

let gmdv = require(`../../../ghc_config`);

const CardInfo = styled.div`
  // as a MBICard except for flex-direction
  position: relative;
  display: flex;
  -webkit-flex-direction: center;
  flex-direction: center;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-top: 1px solid rgba(0,0,0,.125);
  font-weight: 400;
  .cI-content {
    display:inline-block;
    order: 2;
    font-size: 0.8rem;
    img {
      float:left
    }
    .cI-title {
      font-size: 1.2em;
      @media only screen and (max-width: 992px) {
        font-size: 1em;
      }
      @media only screen and (max-width: 767px) {
        font-size: 1.2em;    
      }
    }
    .gatsby-image-wrapper{
      display: inline-block;
      vertical-align: top;
    }
    .cI-mainC {
      display: inline-block;
      font-size: 0.7rem;
    }
    .cI-starsRating {
      background: url('/images/star-ratings-sprite.png') 0 0 repeat-x;
      width: 88px;
      height: 16px;
    }
    .cI-distance {
      font-weight: bolder;
    }
  }
`;

function InfoList(props) {

  let item = props.item
  let index = props.index
  let locations = props.locations

  let address_sl = `${item.node.field_city}, ${gmdv.selectState(item.node.field_state).spa} ${item.node.field_zip}`
  //let altImage = `${item.node.title} - ${item.node.field_city} - ${gmdv.selectState(item.node.field_state).spa}`
  let distance = item.node.distance
  distance = Number((distance).toFixed(1))

  return (
    <CardInfo id={'place_'+index} className="mx-3 pt-2"> 
      <a className="cI-content" 
        href={item.node.path.alias.toLowerCase()}
        target={(item.node.field_center_group === 1) ? `_blank` : `_self`}>
        <div className="cI-title black-text-Genesis">
        {item.node.title}</div>
        {locations[index].node.relationships.field_image_carousel[0] &&
        locations[index].node.relationships.field_image_carousel[0].localFile.childImageSharp &&
        <GatsbyImage 
        alt=""
        image={locations[index].node.relationships.field_image_carousel[0].localFile.childImageSharp.list} />}
        <div className="cI-mainC mx-xs-2 mx-md-0 mx-lg-2 mx-sm-2">
          <div className="cI-address black-text-Genesis">
            <Stars 
              center={item.node.path.alias.toLowerCase()}
              allPracticemaxRatings={props.allPracticemaxRatings}
              /> 
            {item.node.field_address_fl}<br/>
            {address_sl}<br/>
            {distance != 0 ?
            <span className="cI-distance">
              <span className="black-text-Genesis">&gt;&gt;</span> {distance} miles
            </span>: null}
          </div>
        </div>
      </a>
    </CardInfo>
  )
}

export default InfoList;