import { AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';
import { MDBCol, MDBContainer, MDBRow } from 'mdbreact';

import React from 'react';
import StaffCard from './staffcard';

const StaffSection = (props) => {
    const members = props.paragraph.relationships.field_staff_member
    
    let numRows
    (members != null) ? numRows = members.length : numRows = 6
    let xsVl; let mdSm; let mdVl; let lgVl

    if (numRows === 1) {
        xsVl = `12`; mdSm = `12`; mdVl = `12`; lgVl = `12`
    }   
    else {
        if (numRows === 2) {
            xsVl = `12`; mdSm = `12`; mdVl = `12`; lgVl = `6`
        } 
        else {
            if (numRows % 3 === 0) {
                xsVl = `12`; mdSm = `12`; mdVl = `12`; lgVl = `6`
            }
            else {
                xsVl = `12`; mdSm = `12`; mdVl = `12`; lgVl = `6`
            }
        }
    }

    return (
        <>
            {members.length > 0 ? 
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                            <span>Our Staff</span>
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <MDBContainer tag="section">
                            <MDBRow center>
                                {members.map((member, key) => {  
                                    let imageStaff = ''
                                    if (member.relationships.field_member_photo) { 
                                        imageStaff = member.relationships.field_member_photo.localFile.childImageSharp.member_photo.src
                                    }
                                    return  (
                                    <MDBCol 
                                        key={key} 
                                        xs={xsVl} sm={mdSm} md={mdVl} lg={lgVl} 
                                        className={`col-${xsVl} px-1 py-0 m-0`}>
                                        <StaffCard
                                            image={imageStaff}
                                            headerText={member.field_name}
                                            role={member.field_sub_title}
                                            Description={member.field_description}
                                        />
                                    </MDBCol>
                                )})}
                            </MDBRow>
                        </MDBContainer>
                    </AccordionItemPanel>
                </AccordionItem>
            : ''}
        </>
    )
}

export default StaffSection