import { Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel } from 'react-accessible-accordion';

import InlineBodyTransform from '../inlinebodytransform';
import React from 'react';
import styled from 'styled-components';

const AccordionComp = styled(Accordion)`
    border: 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
`;

const VirtualTour = (props) => {

    return (
        <AccordionComp aria-level={4} allowZeroExpanded={true}>
            <AccordionItem>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <span>Video</span>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <InlineBodyTransform bodySearch={props.source} />
                </AccordionItemPanel>
            </AccordionItem>
        </AccordionComp>
    )
}

export default VirtualTour;